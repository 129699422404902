import { Box, List, ListItem, Typography } from "@material-ui/core"
import { Mobile } from "common/MediaQueries"
import NextLink from "common/NextLink"
import { useTranslations } from "next-intl"
import styled from "styled-components"

//Styles
const StyledContactList = styled(List)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`
const StyledPhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledIcon = styled.img``
const StyledIconWrapper = styled.div`
  /* max-width: 130px; */
  display: flex;
  justify-content: space-between;
  width: auto;
`
const StyledListItem = styled.div<{ href?: string; target?: string }>`
  justify-content: end;

  :hover {
    background-color: transparent;
  }

  a:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
`

const StyledMobile = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledText = styled(Typography)`
  font-weight: 500;
`

const HeaderSocial = () => {
  const t = useTranslations("headerMenu")

  //Render
  return (
    <StyledMobile>
      <div>
        <Box mb={3}>
          <StyledText variant="body1">{t("followUs")}</StyledText>
        </Box>
        <StyledIconWrapper>
          <StyledContactList aria-label="contact us list">
            <NextLink
              href="https://www.facebook.com/amot.investments/"
              openInNewTab
              passHref
            >
              <StyledListItem target="_blank">
                <StyledIcon aria-label="Facebook" src={"/fb.svg"} />
              </StyledListItem>
            </NextLink>

            <NextLink
              href="https://www.linkedin.com/company/amot-investments/"
              openInNewTab
              passHref
            >
              <StyledListItem target="_blank">
                <StyledIcon aria-label="Linkedin" src={"/in.svg"} />
              </StyledListItem>
            </NextLink>
            <NextLink
              href="https://www.youtube.com/channel/UCO6ub951hybKn_C_dY1bzEw?view_as=subscriber"
              openInNewTab
              passHref
            >
              <StyledListItem target="_blank">
                <StyledIcon aria-label="Youtube" src={"/youtube.svg"} />
              </StyledListItem>
            </NextLink>
            <NextLink
              href="https://www.pinterest.com/AmotInvestments/"
              openInNewTab
              passHref
            >
              <StyledListItem target="_blank">
                <StyledIcon aria-label="pinterest" src={"/pinterest.svg"} />
              </StyledListItem>
            </NextLink>
            <NextLink
              href="https://www.instagram.com/amot_investment"
              openInNewTab
              passHref
            >
              <StyledListItem target="_blank">
                <StyledIcon aria-label="instagram" src={"/insta.svg"} />
              </StyledListItem>
            </NextLink>
          </StyledContactList>
        </StyledIconWrapper>
      </div>
      <Mobile>
        <StyledPhoneWrapper>
          <Box mb={3}>
            <StyledText variant="body1">{t("talkToUs")}</StyledText>
          </Box>
          <List aria-label="contact telephone">
            <ListItem>
              <NextLink href="tel:*2668">
                <StyledIcon src={"/telephoneicon2.svg"} />
              </NextLink>
            </ListItem>
          </List>
        </StyledPhoneWrapper>
      </Mobile>
    </StyledMobile>
  )
}

export default HeaderSocial
