import { Box, Container, Typography } from "@material-ui/core"
import { MallPage } from "api"
import NextLink from "common/NextLink"
import { LOCALE } from "common/utils/constants"
import HeaderSocial from "components/headerMenu/HeaderSocial"
import { useLayoutContext } from "context/LayoutContext"
import { useTranslations } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import { MainRoutes } from "~/routes/mainRoutes"
import { ObjectKeys } from "../common/utils/iterators"

const StyledAppBarContainer = styled.footer`
  z-index: 1000 !important;
  position: static;
  background: white;
`
const StyledAppBar = styled.div`
  z-index: 1000 !important;
  background-color: ${(p) => p.theme.colors.alabaster};
  align-items: center;
`
const StyledToolbar = styled.div`
  padding: 33px 5px 43px;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ${(p) => p.theme.breakpoints.up("sm")} {
    flex-direction: row;
    justify-content: space-around;
    align-items: self-start;
  }
`

const StyledLanguageLink = styled(Typography).attrs({ component: "a" })`
  color: ${(p) => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 300;
  &:hover {
    background-color: transparent;
  }
  min-width: 0;
  padding: 0;
`
const StyledLogoContainer = styled(Box)`
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-bottom: ${(p) => p.theme.spacing(10)}px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-inline-end: 16px;
  }
`

const StyledBox = styled.div`
  position: relative;
  border-right: 1px solid ${(p) => p.theme.colors.athensGray};
  padding-right: 45px;

  ${(p) => p.theme.breakpoints.down("sm")} {
    border-right: 0;
    border-top: 1px solid ${(p) => p.theme.colors.athensGray};
    width: 100%;
    padding-top: ${(p) => p.theme.spacing(6)}px;
    margin-top: ${(p) => p.theme.spacing(8.33)}px;
    margin-bottom: ${(p) => p.theme.spacing(8)}px;
    padding-right: 0;
  }
`

const StyledLogo = styled.img`
  width: 122px;
  height: 36px;

  ${(p) => p.theme.breakpoints.up("sm")} {
    width: 88.6px;
    height: 26px;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  flex-wrap: wrap;
  a {
    font-size: 14px;
  }
  .MuiBox-root {
    color: #000615;
    margin-bottom: 10px;
    line-height: 22px;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    justify-content: flex-start;

    .MuiBox-root {
      color: #000615;
      margin-bottom: 10px;
      line-height: 22px;
    }
  }
  ul {
    > div {
      min-width: 150px;
      margin-right: 0;
    }
  }
`
const StyledCopyright = styled(Box)`
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-right: 0;
  }
`
const StyledMintLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 13px;
  margin-bottom: 13px;
`

const StyledInnerBox = styled(Box)`
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 40%;
  }
`

const StyledListItem = styled.li<{
  $locale?: string
}>`
  list-style-type: none;
  margin-bottom: 10px;
  ${(p) => p.$locale === "en" && "max-width: 127px"};
  ${(p) => p.theme.breakpoints.up("md")} {
    max-width: 120px;
  }
`
const StyledImageIcon = styled.img`
  align-self: end;
`

const Footer = () => {
  const { mallsItems } = useLayoutContext()
  const t = useTranslations("footer")
  const { route, asPath, locale } = useRouter()
  const handleLocaleChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    const href = e.currentTarget.getAttribute("href")
    location.href = href
  }

  return (
    <StyledAppBarContainer>
      <StyledAppBar>
        <Container>
          <StyledToolbar>
            <StyledWrapper>
              <StyledLogoContainer>
                <NextLink href="/">
                  <StyledLogo src={"/Logonew2x.png"} alt={t("title")} />
                </NextLink>
              </StyledLogoContainer>
              {/* amot assests */}
              <StyledInnerBox mr={3}>
                <Box mb={2}>
                  <Typography component="h4">{t("assets")}</Typography>
                </Box>
                <Box p="0" m="0" component="ul">
                  {ObjectKeys(MainRoutes["assetsList"]).map((key, index) => {
                    return (
                      <StyledListItem key={`${key}-${index}`}>
                        <Link href={MainRoutes["assetsList"][key]}>
                          <a>{t(key)}</a>
                        </Link>
                      </StyledListItem>
                    )
                  })}
                </Box>
              </StyledInnerBox>
              {/* amot invesments */}
              <StyledInnerBox mr={3}>
                <Box mb={2}>
                  <Typography component="h4">{t("amotInvestments")}</Typography>
                </Box>
                <Box p="0" m="0" component="ul">
                  {ObjectKeys(MainRoutes["investments"]).map((key, index) => {
                    if (key === "accessibilityStatement") return null
                    if (locale === LOCALE.EN && key === "magazine") return null
                    return (
                      <StyledListItem key={`${key}-${index}`}>
                        <Link href={MainRoutes["investments"][key]}>
                          <a>{t(key)}</a>
                        </Link>
                      </StyledListItem>
                    )
                  })}
                  <StyledListItem>
                    <Link href="/amot360">
                      <a>{t("amot360")}</a>
                    </Link>
                  </StyledListItem>
                  <StyledListItem>
                    <Link href="/construction">
                      <a>{t("construction")}</a>
                    </Link>
                  </StyledListItem>
                </Box>
              </StyledInnerBox>
              {/* amot investor-relations */}
              <StyledInnerBox mr={3}>
                <Box mb={2}>
                  <NextLink href="/investor-relations">
                    <Typography component="h4">
                      {t("investorRelations")}
                    </Typography>
                  </NextLink>
                </Box>
                <Box p="0" m="0" component="ul">
                  {ObjectKeys(MainRoutes["relations"]).map((key, index) => {
                    return (
                      <StyledListItem $locale={locale} key={`${key}-${index}`}>
                        <Link href={MainRoutes["relations"][key]}>
                          <a>{t(key)}</a>
                        </Link>
                      </StyledListItem>
                    )
                  })}
                </Box>
              </StyledInnerBox>
              <StyledInnerBox mr={3}>
                <Box mb={2}>
                  <Typography component="h4">
                    {t("mallsAndShopping")}
                  </Typography>
                </Box>
                <Box p="0" m="0" component="ul">
                  {mallsItems
                    ?.filter(
                      (item) =>
                        (item as MallPage)?.additionalMallInfo?.showInMenu
                          ?.value === true
                    )
                    .map((key, index) => {
                      return (
                        <StyledListItem
                          $locale={locale}
                          key={`${key}-${index}`}
                        >
                          <Link
                            href={
                              key?.additionalMallInfo?.externalSiteLink
                                ?.value || ""
                            }
                            passHref
                          >
                            <a target="_blank">{key.title}</a>
                          </Link>
                        </StyledListItem>
                      )
                    })}
                </Box>
              </StyledInnerBox>
              <StyledBox>
                <Box mb={6} display={"flex"} flexDirection={"column"}>
                  <Typography component="h4" variant="srOnly">
                    {t("general")}
                  </Typography>
                  <Box mb={2.2}>
                    <NextLink href={MainRoutes.general.termsOfUse}>
                      <Typography variant="body1">{t("termsOfUse")}</Typography>
                    </NextLink>
                  </Box>
                  <Box mb={2.2}>
                    <NextLink href={MainRoutes.general.privacyPolicy}>
                      <Typography variant="body1">
                        {t("privacyPolicy")}
                      </Typography>
                    </NextLink>
                  </Box>
                  <Box mb={2.2}>
                    <NextLink href={MainRoutes.general.siteMap}>
                      <Typography variant="body1">{t("siteMap")}</Typography>
                    </NextLink>
                  </Box>
                  <Box>
                    <NextLink
                      href={MainRoutes?.investments?.accessibilityStatement}
                      passHref
                    >
                      <a>
                        <Typography>{t("accessibilityStatement")}</Typography>
                      </a>
                    </NextLink>
                  </Box>
                </Box>
                <Box mr={2} display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    flexDirection="row"
                    mb={2.2}
                    gridGap="1em"
                  >
                    <StyledImageIcon
                      src={"/Envelope.svg"}
                      alt={t("emailIcon")}
                      width="18"
                      height="12"
                      loading="lazy"
                    />
                    <Typography>
                      <a href="mailto:marketing@amot.co.il">
                        marketing@amot.co.il
                      </a>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mb={2.2}
                    gridGap="1em"
                  >
                    <StyledImageIcon
                      src={"/Phone.svg"}
                      alt={t("phoneIcon")}
                      width="16"
                      height="16"
                      loading="lazy"
                    />
                    <Typography style={{ direction: "ltr" }} variant="body1">
                      <a href="tel:*2668">*2668</a>
                    </Typography>
                  </Box>
                  <Box mb={2.2}>
                    <Link href={route} as={asPath} locale="en" passHref>
                      <StyledLanguageLink onClick={handleLocaleChange}>
                        English
                      </StyledLanguageLink>
                    </Link>
                  </Box>
                  <Box mb={2.2}>
                    <Link href={route} as={asPath} locale="he" passHref>
                      <StyledLanguageLink onClick={handleLocaleChange}>
                        עברית
                      </StyledLanguageLink>
                    </Link>
                  </Box>
                </Box>
                <HeaderSocial />
              </StyledBox>
            </StyledWrapper>
            <StyledCopyright mt={4}>{t("copyright")}</StyledCopyright>
          </StyledToolbar>
        </Container>
      </StyledAppBar>
      <StyledMintLogo>
        <NextLink href="https://mintapp.co.il" openInNewTab>
          <Image src="/mint-logo.png" width={120} height={14} alt="Mint Logo" />
        </NextLink>
      </StyledMintLogo>
    </StyledAppBarContainer>
  )
}

export default Footer
